/**
 * @generated SignedSource<<e55d7fc31359191c4d7ef91c48637acd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationVisibility = "OPENLYLISTED" | "PRIVATE";
import { FragmentRefs } from "relay-runtime";
export type Forms_updateOrganizationQuery$data = {
  readonly description: string | null | undefined;
  readonly email: string | null | undefined;
  readonly id: string;
  readonly image: string | null | undefined;
  readonly name: string;
  readonly visibility: OrganizationVisibility;
  readonly website: string | null | undefined;
  readonly " $fragmentType": "Forms_updateOrganizationQuery";
};
export type Forms_updateOrganizationQuery$key = {
  readonly " $data"?: Forms_updateOrganizationQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"Forms_updateOrganizationQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Forms_updateOrganizationQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "81357b21fafd9dba38b2e506f660d44e";

export default node;
