/**
 * @generated SignedSource<<34f5bb39b98cccb0c655280a0268c3d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationVisibility = "OPENLYLISTED" | "PRIVATE";
export type OrganizationUpdateInput = {
  description?: string | null | undefined;
  email?: string | null | undefined;
  image?: string | null | undefined;
  name?: string | null | undefined;
  visibility?: OrganizationVisibility | null | undefined;
  website?: string | null | undefined;
};
export type Forms_updateOrganizationMutation$variables = {
  id: string;
  input: OrganizationUpdateInput;
};
export type Forms_updateOrganizationMutation$data = {
  readonly organizationUpdate: {
    readonly errors: ReadonlyArray<{
      readonly message?: string;
    }> | null | undefined;
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"Forms_updateOrganizationQuery">;
    } | null | undefined;
  };
};
export type Forms_updateOrganizationMutation = {
  response: Forms_updateOrganizationMutation$data;
  variables: Forms_updateOrganizationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "MutationError",
  "abstractKey": "__isMutationError"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Forms_updateOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationUpdatePayload",
        "kind": "LinkedField",
        "name": "organizationUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Forms_updateOrganizationQuery"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Forms_updateOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationUpdatePayload",
        "kind": "LinkedField",
        "name": "organizationUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e60adbf810aa8ac5cb7e7c7c532fff66",
    "id": null,
    "metadata": {},
    "name": "Forms_updateOrganizationMutation",
    "operationKind": "mutation",
    "text": "mutation Forms_updateOrganizationMutation(\n  $id: ID!\n  $input: OrganizationUpdateInput!\n) {\n  organizationUpdate(id: $id, input: $input) {\n    organization {\n      ...Forms_updateOrganizationQuery\n      id\n    }\n    errors {\n      __typename\n      ... on MutationError {\n        __isMutationError: __typename\n        message\n      }\n    }\n  }\n}\n\nfragment Forms_updateOrganizationQuery on Organization {\n  id\n  name\n  description\n  email\n  website\n  visibility\n  image\n}\n"
  }
};
})();

(node as any).hash = "72e352079bc0c00cf9678c5b509911aa";

export default node;
