/**
 * @generated SignedSource<<80dd58e4ae79c81c2f20b95cd6883273>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationVisibility = "OPENLYLISTED" | "PRIVATE";
export type OrganizationCreateInput = {
  description?: string | null | undefined;
  email?: string | null | undefined;
  image?: string | null | undefined;
  name: string;
  visibility: OrganizationVisibility;
  website?: string | null | undefined;
};
export type Forms_createOrganizationMutation$variables = {
  connections: ReadonlyArray<string>;
  input: OrganizationCreateInput;
};
export type Forms_createOrganizationMutation$data = {
  readonly organizationCreate: {
    readonly errors: ReadonlyArray<{
      readonly message?: string;
    }> | null | undefined;
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly organizationUser: {
        readonly organization: {
          readonly " $fragmentSpreads": FragmentRefs<"MemberOrganization_organizationCardQuery">;
        };
      } | null | undefined;
    } | null | undefined;
  };
};
export type Forms_createOrganizationMutation = {
  response: Forms_createOrganizationMutation$data;
  variables: Forms_createOrganizationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "MutationError",
  "abstractKey": "__isMutationError"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Forms_createOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationCreatePayload",
        "kind": "LinkedField",
        "name": "organizationCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUser",
                "kind": "LinkedField",
                "name": "organizationUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "MemberOrganization_organizationCardQuery"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Forms_createOrganizationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationCreatePayload",
        "kind": "LinkedField",
        "name": "organizationCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationUser",
                "kind": "LinkedField",
                "name": "organizationUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "image",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationUser",
                        "kind": "LinkedField",
                        "name": "organizationUser",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationStudiesConnection",
                        "kind": "LinkedField",
                        "name": "studies",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationOrganizationUsersConnection",
                        "kind": "LinkedField",
                        "name": "organizationUsers",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendNode",
                "key": "",
                "kind": "LinkedHandle",
                "name": "organizationUser",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  },
                  {
                    "kind": "Literal",
                    "name": "edgeTypeName",
                    "value": "UserOrganizationUsersConnectionEdge"
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "467dd92a195c0e5918bcbfe754bbafbc",
    "id": null,
    "metadata": {},
    "name": "Forms_createOrganizationMutation",
    "operationKind": "mutation",
    "text": "mutation Forms_createOrganizationMutation(\n  $input: OrganizationCreateInput!\n) {\n  organizationCreate(input: $input) {\n    organization {\n      id\n      name\n      organizationUser {\n        organization {\n          ...MemberOrganization_organizationCardQuery\n          id\n        }\n        id\n      }\n    }\n    errors {\n      __typename\n      ... on MutationError {\n        __isMutationError: __typename\n        message\n      }\n    }\n  }\n}\n\nfragment MemberOrganization_organizationCardQuery on Organization {\n  id\n  name\n  image\n  organizationUser {\n    role\n    id\n  }\n  studies {\n    totalCount\n  }\n  organizationUsers {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "18e2e4559616befd1346e0ce5a976be9";

export default node;
