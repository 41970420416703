/**
 * @generated SignedSource<<da672ab88c484cef833b7f36e462e151>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Forms_deleteOrganizationQuery$data = {
  readonly id: string;
  readonly name: string;
  readonly organizationUser: {
    readonly user: {
      readonly id: string;
    };
  } | null | undefined;
  readonly " $fragmentType": "Forms_deleteOrganizationQuery";
};
export type Forms_deleteOrganizationQuery$key = {
  readonly " $data"?: Forms_deleteOrganizationQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"Forms_deleteOrganizationQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Forms_deleteOrganizationQuery",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUser",
      "kind": "LinkedField",
      "name": "organizationUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "497585e63dbe0abf491fc12203ee4195";

export default node;
